// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-calicut-index-tsx": () => import("./../../../src/pages/calicut/index.tsx" /* webpackChunkName: "component---src-pages-calicut-index-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-chennai-index-tsx": () => import("./../../../src/pages/chennai/index.tsx" /* webpackChunkName: "component---src-pages-chennai-index-tsx" */),
  "component---src-pages-coimbatore-index-tsx": () => import("./../../../src/pages/coimbatore/index.tsx" /* webpackChunkName: "component---src-pages-coimbatore-index-tsx" */),
  "component---src-pages-coimbatore-services-tsx": () => import("./../../../src/pages/coimbatore/services.tsx" /* webpackChunkName: "component---src-pages-coimbatore-services-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-flutter-course-kochi-tsx": () => import("./../../../src/pages/flutter-course-kochi.tsx" /* webpackChunkName: "component---src-pages-flutter-course-kochi-tsx" */),
  "component---src-pages-hire-dedicated-developer-tsx": () => import("./../../../src/pages/hire-dedicated-developer.tsx" /* webpackChunkName: "component---src-pages-hire-dedicated-developer-tsx" */),
  "component---src-pages-hyderabad-index-tsx": () => import("./../../../src/pages/hyderabad/index.tsx" /* webpackChunkName: "component---src-pages-hyderabad-index-tsx" */),
  "component---src-pages-hyderabad-services-tsx": () => import("./../../../src/pages/hyderabad/services.tsx" /* webpackChunkName: "component---src-pages-hyderabad-services-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-indore-index-tsx": () => import("./../../../src/pages/indore/index.tsx" /* webpackChunkName: "component---src-pages-indore-index-tsx" */),
  "component---src-pages-our-work-tsx": () => import("./../../../src/pages/our-work.tsx" /* webpackChunkName: "component---src-pages-our-work-tsx" */),
  "component---src-pages-partner-with-us-tsx": () => import("./../../../src/pages/partner-with-us.tsx" /* webpackChunkName: "component---src-pages-partner-with-us-tsx" */),
  "component---src-pages-pune-index-tsx": () => import("./../../../src/pages/pune/index.tsx" /* webpackChunkName: "component---src-pages-pune-index-tsx" */),
  "component---src-pages-pune-services-tsx": () => import("./../../../src/pages/pune/services.tsx" /* webpackChunkName: "component---src-pages-pune-services-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-training-tsx": () => import("./../../../src/pages/training.tsx" /* webpackChunkName: "component---src-pages-training-tsx" */),
  "component---src-pages-trivandrum-index-tsx": () => import("./../../../src/pages/trivandrum/index.tsx" /* webpackChunkName: "component---src-pages-trivandrum-index-tsx" */),
  "component---src-templates-blog-detail-index-tsx": () => import("./../../../src/templates/BlogDetail/index.tsx" /* webpackChunkName: "component---src-templates-blog-detail-index-tsx" */),
  "component---src-templates-custom-index-tsx": () => import("./../../../src/templates/Custom/index.tsx" /* webpackChunkName: "component---src-templates-custom-index-tsx" */),
  "component---src-templates-custom-rich-text-view-tsx": () => import("./../../../src/templates/Custom/RichTextView.tsx" /* webpackChunkName: "component---src-templates-custom-rich-text-view-tsx" */),
  "component---src-templates-dedicated-devs-index-tsx": () => import("./../../../src/templates/DedicatedDevs/index.tsx" /* webpackChunkName: "component---src-templates-dedicated-devs-index-tsx" */),
  "component---src-templates-portfolio-detail-index-tsx": () => import("./../../../src/templates/PortfolioDetail/index.tsx" /* webpackChunkName: "component---src-templates-portfolio-detail-index-tsx" */),
  "component---src-templates-service-detail-index-tsx": () => import("./../../../src/templates/ServiceDetail/index.tsx" /* webpackChunkName: "component---src-templates-service-detail-index-tsx" */),
  "component---src-templates-solution-detail-index-tsx": () => import("./../../../src/templates/SolutionDetail/index.tsx" /* webpackChunkName: "component---src-templates-solution-detail-index-tsx" */),
  "component---src-templates-solution-detail-more-solution-detail-model-index-tsx": () => import("./../../../src/templates/SolutionDetail/MoreSolutionDetailModel/index.tsx" /* webpackChunkName: "component---src-templates-solution-detail-more-solution-detail-model-index-tsx" */),
  "component---src-templates-training-detail-index-tsx": () => import("./../../../src/templates/TrainingDetail/index.tsx" /* webpackChunkName: "component---src-templates-training-detail-index-tsx" */)
}

